import { defineMessages } from "react-intl";

export default defineMessages({
  home: {
    id: "navigation.link.home",
    defaultMessage: "Home",
    description: "",
  },

  // Announcements
  announcements: {
    id: "navigation.link.announcements",
    defaultMessage: "Announcements",
    description: "",
  },
  notices: {
    id: "navigation.link.notices",
    defaultMessage: "Notices",
    description: "",
  },
  updates: {
    id: "navigation.link.updates",
    defaultMessage: "Updates",
    description: "",
  },
  events: {
    id: "navigation.link.events",
    defaultMessage: "Events",
    description: "",
  },
  gmNotes: {
    id: "navigation.link.gmNotes",
    defaultMessage: "GM Notes",
    description: "",
  },

  // Game
  game: {
    id: "navigation.link.game",
    defaultMessage: "Game",
    description: "",
  },
  features: {
    id: "navigation.link.features",
    defaultMessage: "Features",
    description: "",
  },
  adventurersGuide: {
    id: "navigation.link.adventurersGuide",
    defaultMessage: "Adventurer's Guide",
    description: "",
  },
  classIntroduction: {
    id: "navigation.link.classIntroduction",
    defaultMessage: "Class Introduction",
    description: "",
  },
  lore: {
    id: "navigation.link.lore",
    defaultMessage: "Lore",
    description: "",
  },
  history: {
    id: "navigation.link.history",
    defaultMessage: "History",
    description: "",
  },
  statistics: {
    id: "navigation.link.statistics",
    defaultMessage: "Statistics",
    description: "",
  },
  rules: {
    id: "navigation.link.rules",
    defaultMessage: "Rules",
    description: "",
  },

  // collections
  collections: {
    id: "navigation.link.collections",
    defaultMessage: "Collections",
    description: "",
  },
  users: {
    id: "navigation.link.users",
    defaultMessage: "Users",
    description: "",
  },
  characters: {
    id: "navigation.link.characters",
    defaultMessage: "Characters",
    description: "",
  },
  guilds: {
    id: "navigation.link.guilds",
    defaultMessage: "Guilds",
    description: "",
  },

  // Wiki
  communitywiki: {
    id: "navigation.link.communitywiki",
    defaultMessage: "Community Wiki",
    description: "",
  },
  wiki: {
    id: "navigation.link.wiki",
    defaultMessage: "Wiki",
    description: "",
  },
  races: {
    id: "navigation.link.races",
    defaultMessage: "Races",
    description: "",
  },
  classes: {
    id: "navigation.link.classes",
    defaultMessage: "Classes",
    description: "",
  },
  items: {
    id: "navigation.link.items",
    defaultMessage: "items",
    description: "",
  },
  crafting: {
    id: "navigation.link.crafting",
    defaultMessage: "Crafting",
    description: "",
  },
  maps: {
    id: "navigation.link.maps",
    defaultMessage: "Maps",
    description: "",
  },
  monsters: {
    id: "navigation.link.monsters",
    defaultMessage: "Monsters",
    description: "",
  },
  skills: {
    id: "navigation.link.skills",
    defaultMessage: "Skills",
    description: "",
  },

  // Multimedia
  multimedia: {
    id: "navigation.link.multimedia",
    defaultMessage: "Multimedia",
    description: "",
  },
  artworks: {
    id: "navigation.link.artworks",
    defaultMessage: "Artworks",
    description: "",
  },
  downloads: {
    id: "navigation.link.downloads",
    defaultMessage: "Downloads",
    description: "",
  },
  screenshots: {
    id: "navigation.link.screenshots",
    defaultMessage: "Screenshots",
    description: "",
  },
  wallpapers: {
    id: "navigation.link.wallpapers",
    defaultMessage: "Wallpapers",
    description: "",
  },
  videos: {
    id: "navigation.link.videos",
    defaultMessage: "Videos",
    description: "",
  },
  soundtracks: {
    id: "navigation.link.soundtracks",
    defaultMessage: "Soundtracks",
    description: "",
  },

  // Support
  support: {
    id: "navigation.link.support",
    defaultMessage: "Support",
    description: "",
  },
  help: {
    id: "navigation.link.help",
    defaultMessage: "Help",
    description: "",
  },
  faq: {
    id: "navigation.link.faq",
    defaultMessage: "FAQ",
    description: "",
  },
  securityCenter: {
    id: "navigation.link.securityCenter",
    defaultMessage: "Security Center",
    description: "",
  },
  development: {
    id: "navigation.link.development",
    defaultMessage: "Development",
    description: "",
  },
  submitTicket: {
    id: "navigation.link.submitTicket",
    defaultMessage: "Submit Ticket",
    description: "",
  },
  contact: {
    id: "navigation.link.contact",
    defaultMessage: "Contact",
    description: "",
  },
  admin: {
    id: "navigation.link.admin",
    defaultMessage: "Admin",
    description: "",
  },
});
