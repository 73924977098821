/* eslint-disable react/jsx-curly-brace-presence */
import React from "react";
import { IFaqEntry } from "../../interfaces";

const entries: Array<IFaqEntry> = [];
export default entries;

entries.push({
  question: "האם ניתן לשחק?",
  answer: "כֵּן.",
});
