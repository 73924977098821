import { defineMessages } from "react-intl";

export default defineMessages({
  admin: {
    id: "footer.link.admin",
    defaultMessage: "Admin",
    description: "",
  },
  development: {
    id: "footer.link.development",
    defaultMessage: "Development",
    description: "",
  },
  team: {
    id: "footer.link.team",
    defaultMessage: "Team",
    description: "",
  },
  support: {
    id: "footer.link.support",
    defaultMessage: "Support",
    description: "",
  },
  rules: {
    id: "footer.link.rules",
    defaultMessage: "Rules",
    description: "",
  },
  imprint: {
    id: "footer.link.imprint",
    defaultMessage: "Imprint",
    description: "",
  },
  contact: {
    id: "footer.link.contact",
    defaultMessage: "Contact",
    description: "",
  },
  eula: {
    id: "footer.link.eula",
    defaultMessage: "EULA",
    description: "",
  },
  termsOfService: {
    id: "footer.link.termsOfService",
    defaultMessage: "Terms of Service",
    description: "",
  },
  privacyPolicy: {
    id: "footer.link.privacy-policy",
    defaultMessage: "Privacy Policy",
    description: "",
  },
});
