import { useContext } from "react";
import { useIntl } from "react-intl";
import i18n from "./useNavigation.i18n";
import characterClasses from "../data/characterClasses/all";
import characterRaces from "../data/characterRaces/all";
import { EFeatureToggleId, UserContext, FeatureTogglesContext } from "../contexts";
import * as ROUTES from "../routes/paths";
import { INavigationEntry } from "../interfaces";

export default function useMenu(): [Array<INavigationEntry>] {
  const { formatMessage, locale } = useIntl();
  const { isLoggedIn, isGm } = useContext(UserContext);
  const { isFeatureToggleEnabled } = useContext(FeatureTogglesContext);

  const races: INavigationEntry = {
    id: "races",
    route: ROUTES.RACES,
    isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_WIKI_RACES),
    label: formatMessage(i18n.races),
    children: characterRaces.map((entry) => {
      const i18nRaces = entry.i18nName as unknown as Record<string, string>;
      return {
        id: entry.id,
        isEnabled: entry.isEnabled,
        label: i18nRaces[locale] || i18nRaces.en,
        route: `${ROUTES.RACES}/${entry.id}`,
      };
    }),
  };

  const classes: INavigationEntry = {
    id: "classes",
    route: ROUTES.CLASSES,
    isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_WIKI_CLASSES),
    label: formatMessage(i18n.classes),
    children: characterClasses.map((entry) => {
      const i18nRaces = entry.i18nName as unknown as Record<string, string>;
      return {
        id: entry.id,
        isEnabled: entry.isEnabled,
        label: i18nRaces[locale] || i18nRaces.en,
        route: `${ROUTES.CLASSES}/${entry.id}`,
      };
    }),
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const announcements: INavigationEntry = {
    id: "announcements",
    isEnabled: false, // TODO
    label: formatMessage(i18n.announcements),
    children: [
      {
        id: "notices",
        label: formatMessage(i18n.notices),
        route: ROUTES.NOTICES,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_ANNOUNCEMENTS_NOTICES),
      },
      {
        id: "updates",
        label: formatMessage(i18n.updates),
        route: ROUTES.UPDATES,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_ANNOUNCEMENTS_UPDATES),
      },
      {
        id: "events",
        label: formatMessage(i18n.events),
        route: ROUTES.EVENTS,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_ANNOUNCEMENTS_EVENTS),
      },
      {
        id: "gmNotes",
        label: formatMessage(i18n.gmNotes),
        route: ROUTES.GM_NOTES,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_ANNOUNCEMENTS_GM_NOTES),
      },
    ],
  };

  const game: INavigationEntry = {
    id: "game",
    isEnabled: true,
    label: formatMessage(i18n.game),
    children: [
      {
        id: "features",
        label: formatMessage(i18n.features),
        route: ROUTES.FEATURES,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_GAME_FEATURES),
      },
      {
        id: "adventurersGuide",
        label: formatMessage(i18n.adventurersGuide),
        route: ROUTES.ADVENTURERS_GUIDE,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_GAME_ADVENTURERS_GUIDE),
      },
      {
        id: "classIntroduction",
        label: formatMessage(i18n.classIntroduction),
        route: ROUTES.CLASS_INTRODUCTION,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_GAME_CLASS_INTRODUCTION),
      },
      {
        id: "lore",
        label: formatMessage(i18n.lore),
        route: ROUTES.LORE,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_GAME_LORE),
      },
      {
        id: "history",
        label: formatMessage(i18n.history),
        route: ROUTES.HISTORY,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_GAME_HISTORY),
      },
      {
        id: "statistics",
        label: formatMessage(i18n.statistics),
        route: ROUTES.STATISTICS,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_GAME_STATISTICS),
      },
      {
        id: "rules",
        label: formatMessage(i18n.rules),
        route: ROUTES.RULES,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_GAME_RULES),
      },
    ],
  };

  const collections: INavigationEntry = {
    id: "collections",
    isEnabled: isLoggedIn,
    label: formatMessage(i18n.collections),
    children: [
      {
        id: "users",
        label: formatMessage(i18n.users),
        route: ROUTES.USERS,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_COLLECTIONS_USERS),
      },
      {
        id: "characters",
        label: formatMessage(i18n.characters),
        route: ROUTES.CHARACTERS,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_COLLECTIONS_CHARACTERS),
      },
      {
        id: "guilds",
        label: formatMessage(i18n.guilds),
        route: ROUTES.GUILDS,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_COLLECTIONS_GUILDS),
      },
    ],
  };

  const wiki: INavigationEntry = {
    id: "wiki",
    isEnabled: true,
    label: formatMessage(i18n.wiki),
    children: [
      {
        id: "community-wiki",
        label: formatMessage(i18n.communitywiki),
        route: ROUTES.COMMUNITY_WIKI,
        isEnabled: true,
      },
      races,
      classes,
      {
        id: "items",
        label: formatMessage(i18n.items),
        route: ROUTES.ITEMS,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_WIKI_ITEMS),
      },
      {
        id: "crafting",
        label: formatMessage(i18n.crafting),
        route: `${ROUTES.CRAFTING}`,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_WIKI_CRAFTING),
      },
      {
        id: "maps",
        label: formatMessage(i18n.maps),
        route: ROUTES.MAPS,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_WIKI_MAPS),
      },
      {
        id: "monsters",
        label: formatMessage(i18n.monsters),
        route: ROUTES.MONSTERS,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_WIKI_MONSTERS),
      },
      {
        id: "skills",
        label: formatMessage(i18n.skills),
        route: ROUTES.SKILLS,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_WIKI_SKILLS),
      },
    ],
  };

  const multimedia: INavigationEntry = {
    id: "multimedia",
    isEnabled: true,
    label: formatMessage(i18n.multimedia),
    children: [
      {
        id: "downloads",
        label: formatMessage(i18n.downloads),
        route: ROUTES.DOWNLOADS,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_MULTIMEDIA_DOWNLOADS),
      },
      {
        id: "screenshots",
        label: formatMessage(i18n.screenshots),
        route: ROUTES.SCREENSHOTS,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_MULTIMEDIA_SCREENSHOTS),
      },
      {
        id: "wallpapers",
        label: formatMessage(i18n.wallpapers),
        route: ROUTES.WALLPAPERS,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_MULTIMEDIA_WALLPAPERS),
      },
      {
        id: "artworks",
        label: formatMessage(i18n.artworks),
        route: ROUTES.ARTWORKS,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_MULTIMEDIA_ARTWORKS),
      },
      {
        id: "videos",
        label: formatMessage(i18n.videos),
        route: ROUTES.VIDEOS,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_MULTIMEDIA_VIDEOS),
      },
      {
        id: "soundtracks",
        label: formatMessage(i18n.soundtracks),
        route: ROUTES.SOUNDTRACKS,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_MULTIMEDIA_SOUNDTRACKS),
      },
    ],
  };

  const support: INavigationEntry = {
    id: "support",
    isEnabled: true,
    label: formatMessage(i18n.support),
    children: [
      { id: "admin", label: formatMessage(i18n.admin), route: ROUTES.ADMIN, isEnabled: isLoggedIn && isGm },
      {
        id: "help",
        label: formatMessage(i18n.help),
        route: ROUTES.HELP,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_SUPPORT_HELP),
      },
      {
        id: "faq",
        label: formatMessage(i18n.faq),
        route: ROUTES.FAQ,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_SUPPORT_FAQ),
      },
      {
        id: "securityCenter",
        label: formatMessage(i18n.securityCenter),
        route: ROUTES.SECURITY_CENTER,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_SUPPORT_SECURITYCENTER),
      },
      {
        id: "development",
        label: formatMessage(i18n.development),
        route: ROUTES.DEVELOPMENT,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_SUPPORT_DEVELOPMENT),
      },
      {
        id: "submitTicket",
        label: formatMessage(i18n.submitTicket),
        route: ROUTES.SUBMIT_TICKET,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_SUPPORT_SUBMIT_TICKET),
      },
      {
        id: "contact",
        label: formatMessage(i18n.contact),
        route: ROUTES.CONTACT,
        isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_SUPPORT_CONTACT),
      },
    ],
  };

  const root: Array<INavigationEntry> = [
    { id: "home", label: formatMessage(i18n.home), route: ROUTES.HOME, isEnabled: true },
    announcements,
    game,
    collections,
    wiki,
    multimedia,
    support,
  ];
  return [root];
}
