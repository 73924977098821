import React from "react";
import { IFaqEntry } from "../../interfaces";

const entries: Array<IFaqEntry> = [];
export default entries;

entries.push({
  question: "Ist es spielbar?",
  answer: <>Ja.</>,
});
