import React, { useContext } from "react";
import { useIntl } from "react-intl";
import i18n from "./useFooterLinks.i18n";
import { EFeatureToggleId, UserContext, FeatureTogglesContext, ModalsContext } from "../contexts";
import * as ROUTES from "../routes/paths";
import { IFooterLink } from "../interfaces";
import { ContactWindow } from "../components/windows";

export default function useFooterLinks(): [Array<IFooterLink>] {
  const { formatMessage } = useIntl();
  const { isLoggedIn, isGm } = useContext(UserContext);
  const { isFeatureToggleEnabled } = useContext(FeatureTogglesContext);
  const { push: pushModal, pop: popModal } = useContext(ModalsContext);

  /*
  <Link to={TOS}>Website`s Terms of Service</Link>
  <Link to={TOS}>FHX Terms of Service</Link>
  <Link to={HELP}>General Game Rules</Link>
  <Link to={HELP}>Fan Content Guidelines</Link>
  */
  const links: Array<IFooterLink> = [
    {
      id: "admin",
      label: formatMessage(i18n.admin),
      route: ROUTES.ADMIN,
      isEnabled: isLoggedIn && isGm,
    },
    {
      id: "termsOfService",
      label: formatMessage(i18n.termsOfService),
      route: ROUTES.TOS,
      isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_LEGAL_TOS),
    },
    {
      id: "privacyPolicy",
      label: formatMessage(i18n.privacyPolicy),
      route: ROUTES.PRIVACY_POLICY,
      isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_LEGAL_PRIVACY_POLICY),
    },
    {
      id: "eula",
      label: formatMessage(i18n.eula),
      route: ROUTES.EULA,
      isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_LEGAL_EULA),
    },
    {
      id: "rules",
      label: formatMessage(i18n.rules),
      route: ROUTES.RULES,
      isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_GAME_RULES),
    },
    {
      id: "development",
      label: formatMessage(i18n.development),
      route: ROUTES.DEVELOPMENT,
      isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_SUPPORT_DEVELOPMENT),
    },
    {
      id: "team",
      label: formatMessage(i18n.team),
      route: `${ROUTES.DEVELOPMENT}#team`,
      isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_SUPPORT_DEVELOPMENT),
    },
    {
      id: "support",
      label: formatMessage(i18n.support),
      route: ROUTES.HELP,
      isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_SUPPORT_HELP),
    },
    {
      id: "contact",
      label: formatMessage(i18n.contact),
      onClick: (): number =>
        pushModal({
          content: <ContactWindow onCancel={popModal} />,
        }),
      isEnabled: true,
    },
    {
      id: "imprint",
      label: formatMessage(i18n.imprint),
      route: ROUTES.IMPRINT,
      isEnabled: isFeatureToggleEnabled(EFeatureToggleId.PAGE_LEGAL_IMPRINT),
    },
  ];

  return [links];
}
