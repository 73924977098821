import React from "react";
import { IFaqEntry } from "../../interfaces";

const entries: Array<IFaqEntry> = [];
export default entries;

entries.push({
  question: "재생할 수 있습니까?",
  answer: <>Yes.</>,
});
