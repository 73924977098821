import React, { useCallback, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import UserContext from "./UserContext";
import IUserContext, { ILoginMethodResponse } from "./IUserContext";
import IUser from "../../helpers/loginServerApi/interfaces/IUser";
import { NotificationsContext } from "../NotificationsContext";
import {
  login as lsLogin,
  logout as lsLogout,
  getNewAuthToken as lsGetNewAuthToken,
  user as lsUser,
} from "../../helpers/loginServerApi";
import i18n from "./UserProvider.i18n";
import { MINIMUM_PERMLVL_TO_BE_GM } from "../../config";
import EApiPermission from "./EApiPermission";
import { IQueryResultData, IQueryVariables, QUERY } from "./queries/getApiPermissions";

const LOCAL_STORAGE_SYNC_KEY = "LOCAL_SESSION_LAST_SYNC";
const refreshLsDate = (): void => window.localStorage.setItem(LOCAL_STORAGE_SYNC_KEY, Date.now().toString());

export interface IProps {
  children: React.ReactNode;
  onAuthTokenChanged?: (authToken?: string) => void;
}

export default function UserProvider({ children, onAuthTokenChanged }: IProps): React.ReactElement {
  const { formatMessage } = useIntl();
  const { push: pushNotification } = useContext(NotificationsContext);
  const [authToken, setAuthToken] = useState<string>();
  const [authTokenExpiry, setAuthTokenExpiry] = useState<number>();
  const [username, setUsername] = useState<string>();
  const [permLvl, setPermLvl] = useState<number>();
  const [user, setUser] = useState<IUser>();
  const isLoggedIn = !!authToken && !!username;
  const isGm = permLvl !== undefined && permLvl >= MINIMUM_PERMLVL_TO_BE_GM;
  const [apiPermissions, setApiPermissions] = useState<Array<EApiPermission>>([]);
  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery<IQueryResultData, IQueryVariables>(QUERY, {
    variables: { userFilter: { usernameIs: username } },
    skip: !username,
  });

  useEffect(() => {
    if (!queryLoading) {
      if (queryError) {
        console.error(queryError);
      } else {
        const permissionIds = queryData?.users[0].accumulatedPermissions.map(
          (perm) => perm.id,
        ) as Array<EApiPermission>;
        setApiPermissions(permissionIds || []);
      }
    }
  }, [queryData?.users, queryError, queryLoading]);

  const invalidateSession = useCallback((): void => {
    setUsername(undefined);
    setPermLvl(undefined);
    setAuthToken(undefined);
    setAuthTokenExpiry(undefined);
    setUser(undefined);
    if (onAuthTokenChanged) onAuthTokenChanged(undefined);
  }, [onAuthTokenChanged]);

  const updateSession = useCallback(
    (uname: string, token: string, authTokenExpiresIn: number, pLvl: number) => {
      setUsername(uname);
      setPermLvl(pLvl);
      setAuthToken(token);
      setAuthTokenExpiry(authTokenExpiresIn);
      if (onAuthTokenChanged) onAuthTokenChanged(token);
      lsUser(uname, token)
        .then((result) => {
          if (result && result.data) setUser(result.data);
        })
        .catch((error) => {
          console.error(error);
          invalidateSession();
        });
    },
    [invalidateSession, onAuthTokenChanged],
  );

  // Gets a new authToken from the Auth-Server and (when successfull) stores/replaces it inside the context's memory.
  // The used endpoint requires a valid refreshToken being stored inside a cookie.
  const refreshAuthToken = useCallback((): void => {
    lsGetNewAuthToken()
      .then((response) => {
        if (response && response.data) {
          const { data } = response;
          updateSession(data.user.username, data.authToken, data.authTokenExpiresIn, data.user.permLvl);
        } else {
          invalidateSession();
        }
      })
      .catch((error) => {
        console.error(error);
        invalidateSession();
      });
  }, [invalidateSession, updateSession]);

  const login = useCallback(
    (uname: string, password: string): ILoginMethodResponse => {
      invalidateSession();
      return lsLogin({ username: uname, password }).then((response) => {
        if (!response) throw new Error("While trying to log in, auth-server responded without data.");
        const { data, error } = response;
        if (error) {
          const { errors } = error;
          const errorsContainName = (name: string): boolean => errors.some((entry) => entry.name === name);
          if (errorsContainName("INVALID_CREDENTIALS")) return "INVALID_CREDENTIALS";
          if (errorsContainName("INVALID_CAPTCHA")) return "INVALID_CAPTCHA";
          if (errorsContainName("EMAIL_NOT_VERIFIED")) return "EMAIL_NOT_VERIFIED";
          if (errorsContainName("BANNED")) return "BANNED";
          console.error(
            `While trying to log in, auth-server responded with an unknown error: ${JSON.stringify(errors)}`,
          );
          return "INVALID_CREDENTIALS";
        }

        if (!data) throw new Error("While trying to log in, auth-server responded with neither error or data.");

        updateSession(data.user.username, data.authToken, data.authTokenExpiresIn, data.user.permLvl);
        refreshLsDate();
        pushNotification({
          type: "INFO",
          title: formatMessage(i18n.login),
          content: formatMessage(i18n.youAreNowLoggedInAs, { username: data.user.username }),
        });
        return "OK";
      });
    },
    [formatMessage, invalidateSession, pushNotification, updateSession],
  );

  const logout = useCallback((): Promise<boolean> => {
    lsLogout()
      .catch((error) => console.error(error))
      .finally(() => {
        invalidateSession();
        refreshLsDate();
        pushNotification({
          type: "INFO",
          title: formatMessage(i18n.logout),
          content: formatMessage(i18n.youHaveBeenLoggedOut),
        });
      });
    return new Promise((resolve) => resolve(true));
  }, [formatMessage, invalidateSession, pushNotification]);

  // To sync the login state across all open tabs in the browser, we listen for a special key inside localstorage.
  // Whenever it changes, we call refreshTheAuthToken(), so all sessions (tabs) are either be logged in or logged out correctly.
  const localStorageListener = useCallback(
    (event: StorageEvent): void => {
      if (event.key === LOCAL_STORAGE_SYNC_KEY) refreshAuthToken();
    },
    [refreshAuthToken],
  );

  const hasApiPermission = useCallback(
    (id: EApiPermission): boolean => {
      return apiPermissions.includes(id);
    },
    [apiPermissions],
  );

  // Register the localstorage event-listener
  useEffect(() => window.addEventListener("storage", localStorageListener));

  // If we are logged in, we try to refresh the context's authToken continously x seconds before it exceeds.
  useEffect(() => {
    if (authToken && authTokenExpiry) {
      const interval = setInterval(() => {
        refreshAuthToken();
      }, authTokenExpiry - 5000);
      return (): void => clearInterval(interval);
    }
    return undefined;
  }, [authToken, authTokenExpiry, refreshAuthToken]);

  // When refreshing/re-visiting the page and no authToken is present,
  // we try to get a new authToken to become logged in again using the refreshToken from cookie.
  useEffect(() => {
    if (!authToken) refreshAuthToken();
  });

  const context: IUserContext = {
    user,
    authToken,
    login,
    logout,
    isLoggedIn,
    isGm,
    hasApiPermission,
  };

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
}
