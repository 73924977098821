import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { useIntl } from "react-intl";
import { Button, Checkbox, EButtonType } from "@fhx/ui";
import { Link, useNavigate } from "react-router-dom";
import styles from "./ContactWindow.module.scss";
import DefaultWindow from "../../DefaultWindow/DefaultWindow";
import i18n from "./ContactWindow.i18n";
import { Input, ReCaptcha, TextArea } from "../../../common";
import { HELP, SUBMIT_TICKET } from "../../../../routes/paths";

interface IProps {
  onCancel?: () => void;
  className?: string;
  style?: React.CSSProperties;
}

export default function ContactWindow({ onCancel, className, style }: IProps): React.ReactElement {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);

  const submit = useCallback(() => {
    //
  }, []);

  const footer = useMemo(
    () => (
      <>
        <Button type={EButtonType.PVP_INFO} onClick={onCancel}>
          Cancel
        </Button>
        {!isFormVisible && (
          <Button
            type={EButtonType.CN}
            onClick={(): void => {
              navigate(HELP);
              if (onCancel) onCancel();
            }}
          >
            Game Support
          </Button>
        )}
        {isFormVisible && (
          <Button type={EButtonType.CN} onClick={(): void => submit()}>
            Submit
          </Button>
        )}
      </>
    ),
    [isFormVisible, navigate, onCancel, submit],
  );
  return (
    <DefaultWindow
      header={formatMessage(i18n.contact)}
      footer={footer}
      className={classNames(styles.root, className)}
      style={style}
    >
      {!isFormVisible && (
        <div className={styles.note}>
          <p>This feature is for organizational, legal, or technical inquiries only.</p>
          <p>
            For any <u>game-related or account-related</u> support or contact,
            <br /> please use our{" "}
            <Link to={HELP} onClick={onCancel}>
              common support pages
            </Link>{" "}
            or our{" "}
            <Link to={SUBMIT_TICKET} onClick={onCancel}>
              ticketing system
            </Link>
            .
          </p>
          <p>
            <a className={styles.showFormAnchor} onClick={(): void => setIsFormVisible(true)}>
              I have a serious and non-game-related inquiry
            </a>
          </p>
        </div>
      )}
      {isFormVisible && (
        <form>
          <div>
            <Input label="E-Mail Address" placeholder="Please provide a valid and active e-mail address" />
          </div>
          <div>
            <TextArea
              label="Message"
              wrapperClassName={styles.textareaWrapper}
              placeholder="Please contact in English only."
            />
          </div>

          <div className={styles.checkBoxes}>
            <Checkbox id="checkBox1" />
            <label htmlFor="checkBox1">
              I understand that a response or reaction will be made only to serious or certified inquiries.
            </label>
            <br />
            <Checkbox id="checkBox2" />
            <label htmlFor="checkBox2">
              I understand that legal or internal actions may be initiated against possible infringement, false
              information, or misuse of this contact form.
            </label>
          </div>
          <ReCaptcha
            // TODO
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onChange={(token: string | null): void => {
              //
            }}
          />
        </form>
      )}
    </DefaultWindow>
  );
}
