import { defineMessages } from "react-intl";

const ID_PREFIX = "windows.contactWindow";
const DESC_PREFIX = "[ContactWindow]";

export default defineMessages({
  contact: {
    id: `${ID_PREFIX}.contact`,
    defaultMessage: "Contact",
    description: `${DESC_PREFIX} ContactWindow title.`,
  },
});
