import { gql } from "@apollo/client";
import { IUser, IUserPermission } from "@fhx/api-interfaces";

// TODO
interface IUserFilter {
  usernameIs?: string;
}

export const QUERY = gql`
  query getApiPermissions($userFilter: IUserFilter) {
    users(filter: $userFilter) {
      id
      accumulatedPermissions {
        id
        description
      }
    }
  }
`;

type IPIckedUserPermission = Pick<IUserPermission, "id">;

interface IPickedUser extends Pick<IUser, "id"> {
  accumulatedPermissions: Array<IPIckedUserPermission>;
}

export interface IQueryResultData {
  users: Array<IPickedUser>;
}

export interface IQueryVariables {
  userFilter: IUserFilter;
}
